import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body, #root, input, select {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: content-box;
  }

  #__next {
    position: relative;
  }


	body, input, textarea, button {
		font-family: ${({ theme }) => theme.olist.fontFamily.brand};
	}

  *::-webkit-scrollbar {
  display: none;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;
