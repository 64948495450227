const I18NextHttpBackend = require('i18next-http-backend/cjs');

module.exports = {
  i18n: {
    locales: ['pt-BR'],
    defaultLocale: 'pt-BR',
  },
  backend: {
    addPath: `${process.env.TRANSLATIONS_SERVER}/missing/${process.env.TRANSLATIONS_SERVER_APP_NAME}/{{lng}}/{{ns}}`,
    loadPath: `${process.env.TRANSLATIONS_SERVER}/${process.env.TRANSLATIONS_SERVER_APP_NAME}/{{lng}}/{{ns}}`,
    referenceLng: 'pt-BR',
  },
  ns: ['translation'],
  defaultNS: 'translation',
  react: {
    useSuspense: false,
  },
  debug: process.env.NODE_ENV === 'development',
  fallbackLng: 'pt-BR',
  lng: 'pt-BR',
  initImmediate: false,
  supportedLngs: ['pt-BR'],
  nonExplicitSupportedLngs: false,
  saveMissing: process.env.NODE_ENV === 'development',
  use: [I18NextHttpBackend],
};
