import { LayoutContainer, Wrapper } from './Layout.styles';
import { Header } from '..';

export function Layout({ children }: { children: JSX.Element }) {
  return (
    <LayoutContainer>
      <Header />
      <Wrapper>{children}</Wrapper>
    </LayoutContainer>
  );
}
