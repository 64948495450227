import ImageNext from 'next/image';
import { useTranslation } from 'react-i18next';

import { Title } from '@olist/design-system';

import logo from 'assets/olist-blue.svg';

import { Container } from './Header.styles';

export const Header = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <ImageNext src={logo} alt="olist-logo" />
      <Title>{t('track your orders')}</Title>
    </Container>
  );
};
