import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  display: flex;
  padding: ${({ theme }) => theme.olist.spacing.stack.large};
  position: fixed;
  width: 100%;
  z-index: 1000;

  > h1 {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.olist.breakpoints.medium}) {
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: ${({ theme }) => theme.olist.spacing.stack.large} 0px;

    justify-content: center;
    padding-bottom: ${({ theme }) => theme.olist.spacing.inset.small};
    h1 {
      display: inline;
      color: ${({ theme }) => theme.olist.color.primary.base};
      font-weight: ${({ theme }) => theme.olist.fontWeight.regular};
      padding-top: ${({ theme }) => theme.olist.spacing.stack.small};
    }
  }
`;
