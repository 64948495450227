import { useCallback, useEffect } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { hotjar } from 'react-hotjar';

import { ThemeProvider as DSThemeProvider } from '@olist/design-system';
import { UnitedGlobalStyle } from '@olist/united';

import { Layout } from 'components/common/Layout';
import AppProvider from 'contexts/providers';
import { hotjarAppId, hotjarAppVersion } from 'utils/hotjar';

import { GlobalStyle } from '@styles/globals';

import nextI18NextConfig from '../../next-i18next.config.js';

import type { AppProps } from 'next/app';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const cleanupLocalData = useCallback(() => {
    const tags = ['__NEXT_DATA__'];

    // deepcode ignore ForEachReturns: <please specify a reason of ignoring this>
    tags.forEach((tag) => {
      if (!tag) return null;

      const node = document.getElementById(tag);
      if (node) node.remove();

      return null;
    });
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    cleanupLocalData();
  }, [cleanupLocalData]);

  useEffect(() => {
    hotjar.initialize(Number(hotjarAppId), Number(hotjarAppVersion));
  }, []);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="favicon.svg" />
        <title>Tracking | Olist</title>
      </Head>
      <DSThemeProvider>
        <GlobalStyle />
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </AppProvider>
        </QueryClientProvider>
        <UnitedGlobalStyle />
        <GlobalStyle />
      </DSThemeProvider>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
