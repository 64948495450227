import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { LoadingPage } from 'components/common/Loading';

import { AuthProvider } from './auth';

export type AppProviderProps = { children: ReactNode };

const AppProvider = ({ children }: AppProviderProps) => {
  const { ready } = useTranslation();

  if (!ready) return <LoadingPage />;

  return <AuthProvider>{children}</AuthProvider>;
};

export default AppProvider;
