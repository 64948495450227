import styled from 'styled-components';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100vw;
`;

const Wrapper = styled.div`
  height: 95vh;
  padding-top: calc(2 * ${({ theme }) => theme.olist.spacing.stack.large});
`;
export { LayoutContainer, Wrapper };
