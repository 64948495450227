import ImageNext from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Button } from '@olist/design-system';
import { Flex, Link, Text } from '@olist/united';

import notFound from 'assets/not-found-error.svg';

interface FeedbackBlankStateProps {
  title: string;
  subtitle: string;
  imageSource?: string;
  link?: {
    href: string;
    text: string;
    variation: string;
  };
}

export const FeedbackBlankState = ({
  imageSource,
  link,
  subtitle,
  title,
}: FeedbackBlankStateProps) => {
  const { t } = useTranslation();

  const { reload } = useRouter();

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center" mx="auto">
      <ImageNext src={imageSource || notFound} alt="img-error" />

      <Text.H3 mt={3} textAlign="center">
        {title}
      </Text.H3>
      <Text mb={3} color="textLight" textAlign="center" mt={3}>
        {subtitle}
      </Text>
      {link ? (
        <Link mt={3} {...link}>
          {link.text}
        </Link>
      ) : (
        <Button onClick={reload}>{t('reload')}</Button>
      )}
    </Flex>
  );
};
