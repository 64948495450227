import { useEffect, useState } from 'react';

function getSessionStorageOrDefault(key: string, defaultValue: any) {
  if (typeof window !== 'undefined') {
    try {
      const stored = sessionStorage.getItem(key);
      if (!stored) {
        return defaultValue;
      }
      return JSON.parse(stored);
    } catch (error) {
      return '';
    }
  }
  return '';
}

export default function useSessionStorage(key: string, defaultValue: any) {
  const [value, setValue] = useState(getSessionStorageOrDefault(key, defaultValue));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
}
