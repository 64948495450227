import { FC, createContext } from 'react';
import type { ReactNode } from 'react';

import useSessionStorage from 'hooks/common/useSessionStorage';

export type AuthProviderProps = { children: ReactNode };

type InfoAuth = {
  pathname: string;
  documentNumber: string;
  zipCode: string;
};
export interface IAuthContext {
  info: InfoAuth;
  handleAuth: (infoAuth: InfoAuth) => void;
}

const AuthContext = createContext({} as IAuthContext);

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authInfo, setAuthInfo] = useSessionStorage('authSession', '');

  const handleAuth = (infoAuth: InfoAuth) => {
    setAuthInfo(infoAuth);
  };

  return (
    <AuthContext.Provider value={{ handleAuth, info: authInfo }}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
