import { Loading } from '@olist/united';

import { Container } from './Loading.styles';

export const LoadingPage = () => {
  return (
    <Container>
      <Loading />
    </Container>
  );
};
